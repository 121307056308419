import axios from "axios";
import { currencyTypes } from "../../constants/taxCurrencies";
import _ from "lodash";
import moment from "moment"

export const kotPrinter = (printReciptData,receiptType) => {
    // console.log("printReciptData=============>",printReciptData);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let selectedTableData = JSON.parse(localStorage.getItem("tableName"));

let printData = Object.entries(printReciptData).map((element, index)=>{
  if(element[1].length>0){
   let newData = element[1].reduce((result, current) => {
      const category = current.productCategoryName;
      
      if (!result[category]) {
        result[category] = [];
      }
      if(current.order === "N"){
      result[category].push(current);
      }
      return result;
    }, {});
    element.newData = newData
  }else{

  }
  return element
});

console.log(printData)

let finalData = printData.map((item)=>{
 return Object.entries(item?.newData).map((ele)=>{
  if(ele[1].length>0){
    return `
    <ticket printer="${item[0]}">
      <line>
        <line size="1">
          <text align="center" length="42" style="font-size:24px;font-weight:bold;">Kitchen Order Ticket</text>
        </line>
      </line>
      <line>
        <text align="center" length="42" style="font-weight:semibold;">KOT${localStorage.getItem("orderId")}</text>
      </line>
      <line>
        <text align="left" length="42">Table:${selectedTableData.name} Section:${selectedTableData.sectionName}</text>
      </line>
      <line>
        <text align="left" length="42">Date:${moment(new Date()).format("YYYY-MM-DD HH:mm")}</text>
      </line>
      <line size="1">
        <text align="center" length="42">${ele[0]}</text>
      </line>
      <line>
        <text align="left" length="15">Item</text>
        <text align="right" length="8">Qty</text>
        <text align="right" length="16">Priority</text>
      </line>
      <line>
        <text style="text-align: left; padding-right: 10px;">-----------------------------------------------</text>
      </line>
      ${ele[1]?.map((element) => {
          return `
          <line>
          <text align="left" length="15" style="text-align: left; padding-right: 10px;">${element.name}</text>
             <text align="right" length="8">${element.weight}</text>
             <text align="right" length="14">${element?.notes === undefined ? "" :  element?.notes}</text>
         </line>
          `
      })}
    </ticket>
`;
  }
  })
});

  let finalValue = `<output>${[].concat(...finalData)}</output>`

  axios
    .post(`${printerURL}printer`, finalValue.toString(), {
      headers: { "Content-Type": "text/plain" },
    })
    .then(() => {
      console.log("XML print success !");
    })
    .catch(() => {
      console.log("XML print failed !");
    });
};
